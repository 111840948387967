<template>
  <section class="container mx-auto mt-15 lg:px-36">
    <h1 class="text-center text-dark font-black text-above-h2 leading-56px tracking-lg uppercase pt-7.5">{{ item.title }}</h1>
    <h2 class="text-center text-dark font-normal text-2h6 leading-sm tracking-tiny italic font-serif mb-15 pt-5 pb-10">{{ item.subtitle }}</h2>
  </section>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'PageTitle',
  extends: Blok
}
</script>

<style lang="scss" scoped>
h1 {
  mask-image: url('/assets/texture-mask-invert.png');
}
</style>
